<template>
    <div class="con-wrap">
        <CarrotTitle title="KPI 상세"></CarrotTitle>
        <div class="board">
            <div class="mt-40">
                <div>
                    <table v-if="view.isLoaded==true" class="table-col">
                        <colgroup>
                            <col width="150">
                            <col width="150">
                            <col width="150">
                            <col width="150">
                            <col width="210">
                            <col width="110">
                        </colgroup>
                        <thead>
                            <tr>
                                <th>부문</th>
                                <th>본부</th>
                                <th>팀</th>
                                <th>팀원</th>
                                <th>직급</th>
                                <th>입사일</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{{ view.info.sector_name }}</td>
                                <td>{{ view.info.office_name }}</td>
                                <td class="txt-ellipsis">{{ view.info.team_name }}</td>
                                <td>{{ view.info.ename }}({{ view.info.kname }})</td>
                                <td>{{ view.info.position_name }}</td>
                                <td>{{ view.info.joindate }}</td>
                            </tr>
                        </tbody>
                    </table>

                    <div class="mt-50 mb-20">
                        <span class="block-title float-left mt-10">업적평가 부문</span>
                        <div class="clear"></div>
                    </div>

                    <table v-if="view.isLoaded==true" class="table-col">
                        <colgroup>
                            <col width="600">
                            <col width="300">
                            <col width="300">
                        </colgroup>
                        <thead>
                            <tr>
                                <th>{{ view.info.ev.wyear }}년 주요 업적 및 성과</th>
                                <th>계량적 기여도</th>
                                <th>평가자 의견</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(irow, i) in view.info.ev.list" :key="i">
                                <td class="txt-left preline">
                                    {{ irow.achievement }}
                                </td>
                                <td class="txt-left preline">
                                    {{ irow.contribution }}
                                </td>
                                <td class="txt-left preline">
                                    {{ irow.opinion }}
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <table v-if="view.isLoaded==true" class="table-row mt-50">
                        <colgroup>
                            <col width="170">
                            <col width="130">
                            <col span="3">
                        </colgroup>
                        <tbody>
                            <tr>
                              <!-- 인사팀에서 사용자 화면 볼때 중간평가/최종평가 노출-->
                              <th :rowspan="store.state.pd_auth? 3 : 1 ">업적종합평가</th>
                                <th>본인평가</th>
                                <td class='lock'>
                                    <div></div>
                                    <label><input type="radio" id="Self_Ass1" name="Self_Ass" value="3" v-model="view.score" readonly="readonly"><span class="ml-5 mr-20"> Exceptional</span></label>
                                </td>
                                <td class='lock'>
                                    <div></div>
                                    <label><input type="radio" id="Self_Ass2" name="Self_Ass" value="2" v-model="view.score" readonly="readonly"><span class="ml-5 mr-20"> Excellent</span></label>
                                </td>
                                <td class='lock'>
                                    <div></div>
                                    <label><input type="radio" id="Self_Ass3" name="Self_Ass" value="1" v-model="view.score" readonly="readonly"><span class="ml-5 mr-20"> Need to Improve</span></label>
                                </td>
                            </tr>
                            <tr v-if="store.state.pd_auth" >
                              <th>중간평가</th>
                              <td class='lock'>
                                <div></div>
                                <label><input type="radio" id="top_Ass1" name="Top_Ass" value="3" v-model="view.top_score" readonly="readonly"><span class="ml-5 mr-20"> Exceptional</span></label>
                              </td>
                              <td class='lock'>
                                <div></div>
                                <label><input type="radio" id="top_Ass2" name="Top_Ass" value="2" v-model="view.top_score" readonly="readonly"><span class="ml-5 mr-20"> Excellent</span></label>
                              </td>
                              <td class='lock'>
                                <div></div>
                                <label><input type="radio" id="top_Ass3" name="Top_Ass" value="1" v-model="view.top_score" readonly="readonly"><span class="ml-5 mr-20"> Need to Improve</span></label>
                              </td>
                            </tr>
                            <tr v-if="store.state.pd_auth" >
                              <th>최종평가</th>
                              <td class='lock'>
                                <div></div>
                                <label><input type="radio" id="final_Ass1" name="Final_Ass" value="3" v-model="view.final_score" readonly="readonly"><span class="ml-5 mr-20"> Exceptional</span></label>
                              </td>
                              <td class='lock'>
                                <div></div>
                                <label><input type="radio" id="final_Ass2" name="Final_Ass" value="2" v-model="view.final_score" readonly="readonly"><span class="ml-5 mr-20"> Excellent</span></label>
                              </td>
                              <td class='lock'>
                                <div></div>
                                <label><input type="radio" id="final_Ass3" name="Final_Ass" value="1" v-model="view.final_score" readonly="readonly"><span class="ml-5 mr-20"> Need to Improve</span></label>
                              </td>
                            </tr>
                        </tbody>
                    </table>
                    <button class="btn-default float-left mt-30" @click="view.goList">목록</button>
                    <div class="clear"></div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import { onMounted, reactive } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js'
import {useStore} from "vuex";


export default {
    layout:"myPIMS",
    components: {
    },
    setup() {
        const router = new useRouter();
        const route  = new useRoute();
        const toast  = useToast();
        const store = useStore();
        const pop = reactive({
            // Popup 노출 여부
            isShow: false
        });

        const view = reactive({
            isLoaded : false,
            isAuth   : false,
            // New Post
            idx  : 0,
            year : "",
            info : { 
                ev: { 
                    wyear:"", list:[], score:""
                }
            },
            score : "",
            top_score   : "",
            final_score : "",
            mentonedu_score : "",
            taboonatt_score : "",
            longevity_score : "",

            doSearch : () => {
                let params = {
                    idx : view.idx
                };
                axios.get("/rest/mypims/getKPI", { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        view.info = res.data;

                        view.score       = view.info.ev.score;
                        view.top_score   = view.info.ev.top_score;
                        view.final_score = view.info.ev.final_score;

                        view.mentonedu_score = view.info.ev.mentonedu_score;
                        view.taboonatt_score = view.info.ev.taboonatt_score;
                        view.longevity_score = view.info.ev.longevity_score;

                        view.isAuth = view.info.is_auth;

                        view.isLoaded = true;
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },

            goList : () => {
                router.push({
                    name : 'myPIMS-PBCKPIList'
                });
            }
        });
        
        onMounted(() => {
            view.idx = route.params.idx;
            if ( !view.idx ) {
                router.back(-1);
            }

            view.doSearch();
        });

        return {pop, view,store};
    }
}
</script>

<style lang="scss" scoped>
.lock {
    position:relative; z-index:1;
    > label {
        position:relative; z-index:1;
    }
    > div {
        position:absolute; top:0;left:0;width:100%;height:100%;z-index:2;
    }
}
</style>